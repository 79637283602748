import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f44336', // Exempel på primärfärg
    },
    secondary: {
      main: '#3f51b5', // Exempel på sekundärfärg
    },
    // Du kan lägga till fler färger efter behov
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    // Definiera fler stilar efter behov
  },
});

export default theme;