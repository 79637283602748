import logo from './logo-brikka.svg';
import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';

function App() {
  return (
    <>
      <AppBar position="static" style={{ 
          backgroundColor: 'white', 
          boxShadow: 'none', 
          height: '60px',
          padding: '20px'
        }}>
       <Toolbar>
          <img src={logo} className="App-logo" alt="logo" style={{ height: '100%' }} />
        </Toolbar>
      </AppBar>
      <Container style={{ 
          maxWidth: '100%', // Begränsa maxbredden
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          height: 'calc(100vh - 60px)', // Subtrahera AppBar-höjden
          margin: 'auto', // Centrera i viewporten
          paddingLeft: '40px', // Fast vänster padding
          paddingRight: '40px', // Fast höger padding
        }}>
        <Typography variant="body1" style={{ textAlign: 'left', maxWidth: 'calc(100% - 80px)' }}>
          Hej!<br/> 
          Välkommen till Brikka.<br/>
          Vi är bra på att utveckla specialanpassade AI-applikationer.<br/>
          Hör av dig till <a href="mailto:hej@brikka.se">hej@brikka.se</a> så berättar vi mer.
          {/* Hur kan vi hjälpa dig? */}
        </Typography>
        {/* Övriga komponenter kommer här */}
      </Container>
    </>
  );
}

export default App;
